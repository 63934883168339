import {IApartmentDto} from "../../dtos/apartments/IApartmentDto";
import {IHotelDto} from "../../dtos/hotels/IHotelDto";
import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {AppDependencies} from "../../services/appDependencies";

interface MainState {
    selectedApartments: IApartmentDto[];
    selectedHotel?: IHotelDto;
}

const initialState: MainState = {
    selectedApartments: [],
}

export const mainSlice = createSlice({
    name: "main",
    initialState,
    reducers: {
        setSelectedHotel: (state, action) => {
            state.selectedHotel = action.payload.hotel;
            AppDependencies.Axios.defaults.headers.common['hotelId'] = action.payload.hotel?.id;
        },
        setSelectedApartments: (state, action) => {
            state.selectedApartments = action.payload.apartments;
        }
    }
});

export const {setSelectedHotel, setSelectedApartments} = mainSlice.actions;
export const getSelectedHotel = (state: RootState) => state.main.selectedHotel;
export const getSelectedApartments = (state: RootState) => state.main.selectedApartments;
export const main = mainSlice.reducer;