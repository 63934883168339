import {AppDependencies} from "./appDependencies";
import {IRentChargeResponseDto} from "../dtos/analytics/IRentChargeResponseDto";
import {IRoomSalesResponseDto} from "../dtos/analytics/IRoomSalesResponseDto";
import {IRentChargeByApartmentResponseDto} from "../dtos/analytics/IRentChargeByApartmentResponseDto";
import {format, formatISO, formatRFC3339} from "date-fns";
import {Format} from "devextreme-react/chart";
import {formatToAPI} from "../helpers/dateHelper";

export class AnalyticsService {
    async GetRentChargeInformation(hotelId?: string): Promise<IRentChargeResponseDto | undefined>  {
        let axiosConfig = {}
        if (hotelId) {
            axiosConfig = {
                headers: {
                    'hotelId': hotelId,
                }
            }
        }

        const response = await AppDependencies.Axios.get('/Analytics/RentCharge', axiosConfig);
        if (response?.status === 200) {
            return response.data;
        }
        return undefined;
    }
    async GetRentChargeByApartmentInformation(apartmentId: string, startDate: Date, endDate: Date): Promise<IRentChargeByApartmentResponseDto | undefined>  {
        let axiosConfig = {} 
        const response = await AppDependencies.Axios.get(`/Analytics/RentChargeByApartment/${apartmentId}/${formatToAPI(startDate)}/${formatToAPI(endDate)}`, axiosConfig);
        if (response?.status === 200) {
            return response.data;
        }
        return undefined;
    }
    async GetRoomSalesInformation(apartmentId: string, startDate: Date, endDate: Date): Promise<IRoomSalesResponseDto | undefined> {
        let axiosConfig = {}
        // if (hotelId) {
        //     axiosConfig = {
        //         headers: {
        //             'hotelId': hotelId,
        //         }
        //     }
        // }
        //
        const response = await AppDependencies.Axios.get(`/Analytics/RoomSales/${apartmentId}/${format(startDate, "yyyy-MM-dd")}/${format(endDate, "yyyy-MM-dd")}`, axiosConfig);
        if (response?.status === 200) {
            return response.data;
        }
        return undefined;
    }
}