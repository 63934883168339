import {Button, Form, FormGroup, Modal} from "react-bootstrap";
import {Resolver, useForm} from "react-hook-form";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {fi} from "date-fns/locale";
import {toast} from "react-hot-toast";
import {AppDependencies} from "../../services/appDependencies";

interface IProps {
    show: boolean;
    onClose: () => void;
}

type FormValues = {
    oldPassword: string;
    newPassword: string;
    newPasswordConfirmation: string;
}

const resolver: Resolver<FormValues> = async (values) => {
    const errors: Partial<Record<keyof FormValues, { type: string; message: string }>> = {};

    if (!values.oldPassword) {
        errors.oldPassword = {
            type: 'required',
            message: 'Old password is required.',
        };
    }

    if (!values.newPassword) {
        errors.newPassword = {
            type: 'required',
            message: 'New password is required.',
        };
    }

    if (!values.newPasswordConfirmation) {
        errors.newPasswordConfirmation = {
            type: 'required',
            message: 'Password confirmation is required.',
        };
    } else if (values.newPassword !== values.newPasswordConfirmation) {
        errors.newPasswordConfirmation = {
            type: 'mismatch',
            message: 'New password and confirmation do not match.',
        };
    }

    return {
        values: Object.keys(errors).length === 0 ? values : {},
        errors,
    };
};

export const ChangePasswordModal = ({show, onClose}: IProps) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {register, handleSubmit, formState: {errors}} = useForm<FormValues>({resolver});
    const {t} = useTranslation();

    const onSubmit = async (data: FormValues) => {
        try {
            setIsSubmitting(true);

            const identityService = AppDependencies.IdentityService;
            const result = await identityService.ChangePasswordCabinet({
                oldPassword: data.oldPassword,
                newPassword: data.newPassword
            })
            if (result) {
                onClose();
                toast.success("Password changed successfully!");
                return;
            }
        } catch (e) {
            toast.error("Something goes wrong while changing the password!");
        } finally {
            setIsSubmitting(false);
        }
    }

    return (
        <>
            <Modal show={show} onHide={onClose}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Change password</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <FormGroup className={"my-2"}>
                            {/*<Form.Label>Old password</Form.Label>*/}
                            <Form.Control type="password" placeholder="Old password" {...register("oldPassword")}/>
                            {errors.oldPassword && <p className="text-danger">{errors.oldPassword.message}</p>}
                        </FormGroup>
                        <FormGroup className={"my-2"}>
                            {/*<Form.Label>New password</Form.Label>*/}
                            <Form.Control type="password" placeholder="New password" {...register("newPassword")}/>
                            {errors.newPassword && <p className="text-danger">{errors.newPassword.message}</p>}
                        </FormGroup>
                        <FormGroup className={"my-2"}>
                            {/*<Form.Label>Confirm new password</Form.Label>*/}
                            <Form.Control type="password" placeholder="Confirm new password"
                                          {...register("newPasswordConfirmation")}/>
                            {errors.newPasswordConfirmation &&
                                <p className="text-danger">{errors.newPasswordConfirmation.message}</p>}
                        </FormGroup>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={onClose}>Close</Button>
                        <button className="btn btn-primary"
                                type="submit" disabled={isSubmitting}>
                            {isSubmitting && <span className="spinner-border spinner-border-sm me-2"></span>}
                            {t("Send")}
                        </button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}