import {currentApi} from "../configs/apiConfig";
import {AppDependencies} from "./appDependencies";
import {IFolioTransactionDto} from "../dtos/folios/IFolioTransactionDto";

export class FolioService {
    async getFolios(): Promise<IFolioTransactionDto[]> {
        const response = await AppDependencies.Axios.get(`/Folios`);
        return response.data;
    }

    async getFolioById(id: string) {
        const response = await AppDependencies.Axios.get(`/Folios/${id}`);
        return response.data;
    }
}