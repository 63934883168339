import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {ResponseInterceptor} from "./components/ResponseInterceptor";
import {Provider} from "react-redux";
import {store} from "./redux/store";
import {BrowserRouter} from "react-router-dom";
import {Toaster} from "react-hot-toast";
import {MainRouter} from "./router/MainRouter";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.css";
import {initReactI18next} from "react-i18next";
import i18n from "i18next";
import {languages} from "./i18n/languages";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: languages,
        lng: "en", // if you're using a language detector, do not define the lng option
        fallbackLng: "en",

        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <ResponseInterceptor/>
                <MainRouter/>
                <Toaster position={"top-right"}
                         toastOptions={{className: 'react-hot-toast'}}/>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
