import {IApartmentDto} from "../dtos/apartments/IApartmentDto";
import {AppDependencies} from "./appDependencies";

export  class ApartmentService {
    async GetApartments(hotelId?: string): Promise<IApartmentDto[] | undefined> {
        let axiosConfig = {}
        if (hotelId) {
            axiosConfig = {
                headers: {
                    'hotelId': hotelId,
                }
            }
        }
        const response = await AppDependencies.Axios.get("/Rooms", axiosConfig);
        if (response && response.status === 200) {
            return response.data;
        }
        return undefined;
    }
}