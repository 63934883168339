import {Form, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Controller, Resolver, useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {IRoomDto} from "../../dtos/rooms/IRoomDto";
import {AppDependencies} from "../../services/appDependencies";
import {IApartmentDto} from "../../dtos/apartments/IApartmentDto";
import {useAppSelector} from "../../hooks/reduxHooks";
import {getSelectedHotel} from "../../redux/slices/mainSlice";
import {DateBox, SelectBox, TextArea, TextBox} from "devextreme-react";
import {toast} from "react-hot-toast";
import {addDays} from "date-fns";
import {fi} from "date-fns/locale";

interface IProps {
    show: boolean;
    onClose: () => void;
}

type FormValues = {
    roomId: string;
    start: Date;
    end: Date;
    remarks?: string;
}

const resolver: Resolver<FormValues> = async (values) => {
    const errors: Partial<Record<keyof FormValues, { type: string; message: string }>> = {};

    if (!values.roomId) {
        errors.roomId = {
            type: 'required',
            message: 'Room is required.',
        };
    }

    if (!values.start) {
        errors.start = {
            type: 'required',
            message: 'Start date is required.',
        };
    }

    if (!values.end) {
        errors.end = {
            type: 'required',
            message: 'End date is required.',
        };
    }

    return {
        values: Object.keys(errors).length === 0 ? values : {},
        errors,
    };
};

export const CreateReservationModal = ({show, onClose}: IProps) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const {t} = useTranslation();
    const {watch, handleSubmit, formState: {errors}, setValue, control} = useForm<FormValues>({
        resolver,
        defaultValues: {
            start: addDays(new Date(), 1),
            end: addDays(new Date(), 2),
        }
    });

    const [error, setError] = useState<string | undefined>(undefined);
    const selectedHotel = useAppSelector(getSelectedHotel);
    const [apartments, setApartments] = useState<IApartmentDto[] | undefined>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    
    const fetchData = async () => {
        setIsLoading(true);
        try {
            setApartments(await AppDependencies.ApartmentService.GetApartments(selectedHotel?.id));
        } catch (e: any) {
            setError(e.message);
        } finally {
            setIsLoading(false);
        }
    }

    const onSubmit = async (data: FormValues) => {
        try {
            setIsSubmitting(true);

            const reservationService = AppDependencies.ReservationService;
            const result = await reservationService.CreateReservation({
                roomId: data.roomId,
                start: data.start,
                end: data.end,
                remarks: data.remarks
            });

            if (result) {
                toast.success("Reservation created successfully");
                onClose();
                return;
            }
        } catch (e) {
            console.log(e)
        } finally {
            setIsSubmitting(false);
        }

        toast.error("Failed to create reservation");
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (watch("start") && watch("end")) {
            if (watch("start") >= watch("end")) {
                setValue("end", addDays(watch("start"), 1));
            }
        }
    }, [watch("start"), watch("end")]);

    return (
        <>
            <Modal show={true} onHide={onClose}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("CreateReservation")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={"form-group"}>
                            <label htmlFor={"roomId"}>{t("Room")}</label>
                            <Controller
                                name="roomId"
                                control={control}
                                render={({field}) => (
                                    <SelectBox
                                        id={"roomId"}
                                        className={"form-control"}
                                        dataSource={apartments}
                                        valueExpr="id"
                                        displayExpr="name"
                                        value={field.value}
                                        onValueChanged={(e) => field.onChange(e.value)}
                                        validationStatus={errors.roomId ? "invalid" : "valid"}
                                        validationError={errors.roomId && {message: errors.roomId.message}}
                                        validationMessageMode={"always"}
                                    />
                                )}
                            />
                            {/*{errors.roomId && <span className={"text-danger"}>{errors.roomId.message}</span>}*/}
                        </div>
                        <div className={"form-group"}>
                            <label htmlFor={"start"}>{t("StartDate")}</label>
                            <Controller render={({field}) => {
                                return (
                                    <>
                                        <DateBox type={"date"} value={field.value} className={"form-control"}
                                                 onValueChanged={(e) => field.onChange(e.value)}/>
                                    </>
                                )
                            }} name={"start"} control={control}/>
                            {errors.start && <span className={"text-danger"}>{errors.start.message}</span>}
                        </div>
                        <div className={"form-group"}>
                            <label htmlFor={"end"}>{t("EndDate")}</label>
                            <Controller render={({field}) => {
                                return (
                                    <>
                                        <DateBox type={"date"} value={field.value} className={"form-control"}
                                                 onValueChanged={(e) => field.onChange(e.value)}/>
                                    </>
                                )
                            }} name={"end"} control={control}/>
                            {errors.end && <span className={"text-danger"}>{errors.end.message}</span>}
                        </div>
                        <div className={"form-group"}>
                            <label htmlFor={"end"}>{t("Remarks")}</label>
                            <Controller render={({field}) => {
                                return (
                                    <>
                                        <TextArea mode={"text"} text={field.value} className={"form-control"}
                                                  onValueChanged={(e) => field.onChange(e.value)}/>
                                    </>
                                )
                            }} name={"remarks"} control={control}/>
                            {errors.end && <span className={"text-danger"}>{errors.end.message}</span>}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary"
                                type="submit" disabled={isSubmitting}>
                            {isSubmitting && <span className="spinner-border spinner-border-sm me-2"></span>}
                            {t("Send")}
                        </button>
                        <button className={"btn btn-secondary"} onClick={onClose}>{t("Close")}</button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}