import {Button, Modal} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {DateBox} from "devextreme-react";
import {useTranslation} from "react-i18next";

interface IProps {
    show: boolean;
    onHide: (success: boolean, newStartDate?: Date, newEndDate?: Date) => void;
    startDate: Date;
    endDate: Date;
}

export const RevenueFilter = ({show, onHide, startDate, endDate}: IProps) => {
    const {t} = useTranslation();
    const [newStartDate, setNewStartDate] = useState(startDate);
    const [newEndDate, setNewEndDate] = useState(endDate);

    return (
        <>
            <Modal show={show} onHide={() => onHide(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Period</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col">
                            <DateBox value={newStartDate} onValueChanged={(e)=>setNewStartDate(e.value)}/>
                        </div>
                        <div className="col">
                            <DateBox value={newEndDate} onValueChanged={(e)=>setNewEndDate(e.value)}/>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal"
                            onClick={() => onHide(true, newStartDate, newEndDate)}>OK
                    </button>
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                            onClick={() => onHide(false)}>Close
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}