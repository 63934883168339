import {Breadcrumb, BreadcrumbItem, Col, Row} from "react-bootstrap";
import {RentChargeInformation} from "./RentChargeInformation";
import {RoomSalesTurnover} from "./RoomSalesTurnover";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {IApartmentDto} from "../../dtos/apartments/IApartmentDto";
import Select, {SingleValue} from "react-select";
import {useAppSelector} from "../../hooks/reduxHooks";
import {getSelectedHotel} from "../../redux/slices/mainSlice";
import {AppDependencies} from "../../services/appDependencies";
import {DateBox} from "devextreme-react";
import {endOfMonth, startOfMonth} from "date-fns";

interface ISelectOption {
    label: string;
    value: string;
}

export const AnalyticsPage = () => {
    const {t} = useTranslation();

    const [startDate, setStartDate] = React.useState<Date>(startOfMonth(new Date()));
    const [endDate, setEndDate] = React.useState<Date>(endOfMonth(new Date()));

    const [selectedApartment, setSelectedApartment] = React.useState<IApartmentDto | undefined>(undefined);
    const [selectedApartmentOption, setSelectedApartmentOption] = React.useState<ISelectOption | undefined | null>(null);
    const [apartmentOptions, setApartmentOptions] = useState<ISelectOption[] | undefined>([]);
    const [apartments, setApartments] = useState<IApartmentDto[] | undefined>([]);

    const selectedHotel = useAppSelector(getSelectedHotel);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const fetchData = async () => {
        setSelectedApartmentOption(null);
        setSelectedApartment(undefined);
        setIsLoading(true);
        try {
            // console.log(selectedHotel);
            const data = await AppDependencies.ApartmentService.GetApartments(selectedHotel?.id);
            if (data) {
                setApartments(data);
                setApartmentOptions(data.map((apartment) => {
                    return {
                        label: apartment.name,
                        value: apartment.id
                    }
                }));
            } else {
                setError("Error fetching data");
            }
        } catch (e: any) {
            setError(e.message);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, [selectedHotel]);

    const handleChangeApartment = (apartment: SingleValue<ISelectOption> | undefined) => {
        //console.log(apartment);
        setSelectedApartmentOption(apartment as ISelectOption);
        setSelectedApartment(apartments?.find((a) => a.id === apartment?.value));
    }

    const handleChangeStartDate = (date: Date) => {
        setStartDate(startOfMonth(date));
        if (startDate > endDate) {
            setEndDate(endOfMonth(date));
            //console.log("change end date")
        }
    }

    const handleChangeEndDate = (date: Date) => {
        setEndDate(endOfMonth(date));
        if (endDate < startDate) {
            setStartDate(startOfMonth(date));
            //console.log("change end date")
        }
    }

    return (
        <>
            <Breadcrumb>
                <BreadcrumbItem>{t("Home")}</BreadcrumbItem>
                <BreadcrumbItem active>{t("Analytics")}</BreadcrumbItem>
            </Breadcrumb>
            <Row>
                <Col lg={12} className={"d-flex"}>
                    <Select options={apartmentOptions}
                            onChange={handleChangeApartment} value={selectedApartmentOption} isClearable={true}
                            className={"w-25"}/>
                    <div className={"flex-grow-1"}></div>
                    <DateBox value={startDate} onValueChanged={(e) => handleChangeStartDate(e.value)}/>
                    <div className={"mx-2"}></div>
                    <DateBox value={endDate} onValueChanged={(e) => handleChangeEndDate(e.value)}/>
                </Col>
            </Row>
            <RentChargeInformation startDate={startDate} endDate={endDate} selectedApartment={selectedApartment}/>
            <RoomSalesTurnover startDate={startDate} endDate={endDate} selectedApartment={selectedApartment}/>
        </>
    )
}