import {AppDependencies} from "./appDependencies";
import {toast} from "react-hot-toast";
import {AxiosError} from "axios";

export class ReservationService {
    async CreateReservation(reservation: any): Promise<boolean> {
        try {
            const response = await AppDependencies.Axios.post('/Reservation', reservation);
            if (response.status === 200) {
                return true;
            }
        } catch (e: any) {
            console.log(e);
            if (e instanceof AxiosError) {
                const errorMessage = e?.response?.data?.errorMessage;
                if (errorMessage) {
                    toast.error(errorMessage);
                }
            }
        }
        return false;
    }
}