import {axiosApi} from "../configs/apiConfig";
import {IdentityService} from "./identityService";
import {CustomerService} from "./customerService";
import {ApartmentService} from "./apartmentService";
import {HotelService} from "./hotelService";
import {CalendarService} from "./calendarService";
import {RevenueService} from "./revenueService";
import {AnalyticsService} from "./analyticsService";
import {FolioService} from "./folioService";
import {ReservationService} from "./reservationService";

export const AppDependencies = {
    Axios: axiosApi,
    IdentityService: new IdentityService(),
    CustomerService: new CustomerService(), 
    ApartmentService: new ApartmentService(),
    HotelService: new HotelService(),
    AnalyticsService: new AnalyticsService(),
    CalendarService: new CalendarService(),
    RevenueService: new RevenueService(),
    FolioService: new FolioService(),
    ReservationService: new ReservationService()
}