import {useEffect, useRef} from 'react';
import {AppDependencies} from "../services/appDependencies";
import {toast} from "react-hot-toast";
import {parseISO} from "date-fns";
import {useNavigate} from 'react-router-dom';
import {UTCDate} from "@date-fns/utc";

export const ResponseInterceptor = () => {
    const navigate = useNavigate();
    const interceptorId = useRef<number | null>(null);

    const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?$/;
    // const iso8601Regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})([+-]\d{2}:\d{2})$/;


    //const isoDateFormat = "/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?$/";

    function isIsoDateString(value: any): boolean {
        return value && typeof value === "string" && isoDateFormat.test(value);
    }
    
    function parseIso8601Date(value: string): Date {
        const year = value.substring(0, 4);
        const month = value.substring(5, 7);
        const day = value.substring(8, 10);
        const hour = value.substring(11, 13);
        const minute = value.substring(14, 16);
        const second = value.substring(17, 19);
        const date = new Date(
            Number(year),
            Number(month) - 1,
            Number(day),
            Number(hour),
            Number(minute),
            Number(second),
        );
        return date;
    }

    const handleDates = (body: any) => {
        if (body === null || body === undefined || typeof body !== "object") {
            return body;
        }

        for (const key of Object.keys(body)) {
            
            const value = body[key];
            if (isIsoDateString(value)) {
                const dateString = value as unknown as string;
                body[key] = parseISO(value);
            } else if (typeof value === "object") {
                handleDates(value);
            } else if (Array.isArray(value)) {
                for (const item of value) {
                    handleDates(item);
                }
            }
        }
    }

    useEffect(() => {
        interceptorId.current = AppDependencies.Axios.interceptors.response.use(
            (originalResponse) => {
                handleDates(originalResponse);
                return originalResponse;
            });
        // /,
        // (error) => {
        //     if (!error.response) {
        //         toast.error("Network error, please check the internet connection!");
        //         return;
        //     }
        //     switch (error.response.status) {
        //         case 400:
        //             toast.error(error.response.data);
        //             break;
        //         case 401:
        //             navigate("/account/login");
        //             const statusCode = error.response.data.errorCode;
        //             switch (statusCode) {
        //                 case 0:
        //                     toast.error("Invalid login or password!");
        //                     break;
        //                 case 1:
        //                     toast.error("User is locked!");
        //                     break;
        //                 default:
        //                     toast.error("Unknown error!");
        //                     break;
        //             }
        //             break;
        //         case 404:
        //             // toast.error("API Error - Not found!")
        //             return Promise.reject(error);
        //     }
        // });

        return () => {
            const current = Number(interceptorId.current);
            AppDependencies.Axios.interceptors.response.eject(current);
        };
    }, []);

    return null;
};