import {useEffect, useState} from "react";
import {IHotelDto} from "../dtos/hotels/IHotelDto";
import {AppDependencies} from "../services/appDependencies";
import Select, {ActionMeta, SingleValue} from "react-select";
import {useDispatch} from "react-redux";
import {setSelectedHotel} from "../redux/slices/mainSlice";
import {useAppDispatch} from "../hooks/reduxHooks";

interface ISelectOption {
    label: string;
    value: string;
}

export const HotelSelector = () => {
    const [hotels, setHotels] = useState<ISelectOption[] | undefined>([]);
    
    const dispatch = useAppDispatch();

    useEffect(() => {
        const fetchData = async () => {
            const hotels = await AppDependencies.HotelService.GetHotels();
            if (hotels) {
                const newHotels = hotels.map(hotel => {
                    return {label: hotel.name, value: hotel.id}
                });
                setHotels(newHotels);
            } else {
                setHotels(undefined)
            }
        }
        fetchData();
    }, []);

    const handleChangeHotel = (newValue: SingleValue<ISelectOption>, actionMeta: ActionMeta<ISelectOption>) => {
        if (newValue) {
            dispatch(setSelectedHotel({hotel: {id: newValue.value, name: newValue.label}}));
        } else {
            dispatch(setSelectedHotel({hotel: undefined}));
        }
    }

    return (
        <>
            <Select options={hotels} onChange={handleChangeHotel} isClearable={true}/>
        </>
    )
}