import {Route, Router, Routes} from "react-router-dom";
import React from "react";
import HomePage from "../pages/home/HomePage";
import CalendarPage from "../pages/calendar/CalendarPage";
import ApartmentsPage from "../pages/apartments/ApartmentsPage";
import LoginPage from "../pages/identity/LoginPage";
import {MainLayout} from "../layouts/MainLayout";
import {IdentityLayout} from "../layouts/IdentityLayout";
import RequireAuth from "./RequireAuth";
import {AnalyticsPage} from "../pages/analytics/AnalyticsPage";
import {ForgotPasswordPage} from "../pages/identity/ForgotPasswordPage";
import {ChangePasswordPage} from "../pages/identity/ChangePasswordPage";
import {MyFinancePage} from "../pages/myfinances/MyFinancePage";
import FolioInvoicePage from "../pages/reports/FolioInvoicePage";
import {ForgotPasswordSuccessPage} from "../pages/identity/ForgotPasswordSuccessPage";
import {ChangePasswordSuccessPage} from "../pages/identity/ChangePasswordSuccessPage";
import {InvoicePrintForm} from "../pages/myfinances/InvoicePrintForm";
import {maintenanceMode} from "../configs/apiConfig";
import {SiteIsUnderMaintenancePage} from "../pages/maintenance/SiteIsUnderMaintenancePage";

export const MainRouter = () => {

    if (maintenanceMode) {
        return (
            <Routes>
                <Route path={"*"} element={<SiteIsUnderMaintenancePage/>}>
                </Route>
            </Routes>
        )
    }

    return (
        <Routes>
            <Route path={"/"}>
                <Route path="" element={<RequireAuth><MainLayout><HomePage/></MainLayout></RequireAuth>}/>
                <Route path={"home"} element={<RequireAuth><MainLayout><HomePage/></MainLayout></RequireAuth>}/>
                <Route path={"calendar"} element={<RequireAuth><MainLayout><CalendarPage/></MainLayout></RequireAuth>}/>
                <Route path={"apartments"}
                       element={<RequireAuth><MainLayout><ApartmentsPage/></MainLayout></RequireAuth>}/>
                <Route path={"analytics"}
                       element={<RequireAuth><MainLayout><AnalyticsPage/></MainLayout></RequireAuth>}/>
                <Route path={"my-finance"}
                       element={<RequireAuth><MainLayout><MyFinancePage/></MainLayout></RequireAuth>}/>
                <Route path={"folio-invoice/:folioId"}
                       element={<RequireAuth><MainLayout><InvoicePrintForm/></MainLayout></RequireAuth>}/>
            </Route>
            <Route path={"/"}>
                <Route path={"login"} element={<IdentityLayout><LoginPage/></IdentityLayout>}/>
                <Route path={"forgot-password"} element={<IdentityLayout><ForgotPasswordPage/></IdentityLayout>}/>
                <Route path={"forgot-password-success"}
                       element={<IdentityLayout><ForgotPasswordSuccessPage/></IdentityLayout>}/>
                <Route path={"change-password-success"}
                       element={<IdentityLayout><ChangePasswordSuccessPage/></IdentityLayout>}/>
                <Route path={"change-password/:token/:email"}
                       element={<IdentityLayout><ChangePasswordPage/></IdentityLayout>}/>
            </Route>
        </Routes>
    )
}