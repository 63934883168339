import Select, {components} from "react-select";
import ka from "../assets/ge.svg"
import en from "../assets/us.svg"
import ru from "../assets/ru.svg"
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";

interface IProps {
    showTitle: boolean
}

export const LanguageSelector = ({showTitle}: IProps) => {
    const languages = [
        {value: "en", label: "English", image: en},
        {value: "ka", label: "ქართული", image: ka},
        {value: "ru", label: "Русский", image: ru},
    ]

    //@ts-ignore
    const Option = ({children, ...props}) => (
        //@ts-ignore
        <components.Option {...props}>
            <img src={props.data.image} alt={props.data.label} className={"me-2"} width={16}/>
            {showTitle ? props.data.label : ""}
        </components.Option>
    );
    //@ts-ignore
    const SingleValue = ({children, ...props}) => (
        //@ts-ignore
        <components.SingleValue {...props}>
            <img src={props.data.image} alt={props.data.label} className={"me-2"} width={16}/>
            {showTitle ? props.data.label : ""}
        </components.SingleValue>
    );

    const [currentLanguage, setCurrentLanguage] = useState<any>();

    const {i18n} = useTranslation();

    useEffect(() => {
        setCurrentLanguage(languages.find(l => l.value === i18n.language));
    }, [i18n.language]);

    const handleChangeLanguage = (newLanguage: any) => {
        i18n.changeLanguage(newLanguage.value);
    }

    return (
        <>
            <Select options={languages} value={currentLanguage} onChange={handleChangeLanguage} closeMenuOnSelect={true}
                    components={{Option, SingleValue}}/>
        </>
    )
}