import {NavLink} from "react-router-dom";
import logo from "../assets/logo.svg";
import "./NavMenu.css";
import {useAppDispatch} from "../hooks/reduxHooks";
import {doLogout} from "../redux/slices/identitySlice";
import {useTranslation} from "react-i18next";
import {LanguageSelector} from "./LanguageSelector";
import {useState} from "react";

export const NavMenu = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [collapsed, setCollapsed] = useState(true);
    const handleLogout = (e: any) => {
        e.preventDefault();
        dispatch(doLogout())
    }

    const handleToggleNavMenu = () => {
        setCollapsed(!collapsed);
    }

    return (
        <>
            <div className="top-row ps-3 navbar" style={{backgroundColor: "#f7f7f7"}}>
                <div className="container-fluid">
                    <div className="pb-2 navbar-brand">
                        <img src={logo} alt="Next Group" height="40px"/>
                    </div>
                    <button title="Navigation menu" className="navbar-toggler" onClick={handleToggleNavMenu}>
                        <i className="fa-solid fa-bars"></i>
                    </button>
                </div>
            </div>

            <div className={`${collapsed ? " collapse " : ""} nav-scrollable`} onClick={handleToggleNavMenu}>
                <nav className="flex-column">
                    <div className="nav-item px-3">
                        <NavLink className="nav-link" to="/" style={{padding: "0.7rem 0.5rem"}}>
                            <span className="fa-solid fa-user me-3"></span> {t("MyProfile")}
                        </NavLink>
                    </div>
                    <div className="nav-item px-3">
                        <NavLink className="nav-link" to={"/apartments"} style={{padding: "0.7rem 0.5rem"}}>
                            <i className="fa-solid fa-building me-3"></i> {t("MyApartments")}
                        </NavLink>
                    </div>
                    <div className="nav-item px-3">
                        <NavLink className="nav-link" to={"/calendar"} style={{padding: "0.7rem 0.5rem"}}>
                            <i className="fa-solid fa-calendar-days me-3"></i> {t("Calendar")}
                        </NavLink>
                    </div>
                    <div className="nav-item px-3">
                        <NavLink className="nav-link" to={"/analytics"} style={{padding: "0.7rem 0.5rem"}}>
                            <i className="fa-solid fa-chart-line me-3"></i> {t("Analytics")}
                        </NavLink>
                    </div>
                    <div className="nav-item px-3">
                        <NavLink className="nav-link" to={"/my-finance"} style={{padding: "0.7rem 0.5rem"}}>
                            <i className="fa-solid fa-chart-line me-3"></i> {t("MyFinances")}
                        </NavLink>
                    </div>
                    <hr style={{color: "white"}}/>
                    <div className="nav-item px-3">
                        <a href={"#"} className="nav-link" onClick={handleLogout} style={{padding: "0.7rem 0.5rem"}}>
                            <i className="fa-solid fa-right-from-bracket me-3"></i> {t("Logout")}
                        </a>
                    </div>
                </nav>
            </div>
        </>
    )
}