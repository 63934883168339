import {IRevenueResponseItemDto} from "../dtos/rooms/IRevenueResponseItemDto";
import {AppDependencies} from "./appDependencies";

export class RevenueService {
    async GetRevenue(startDate: Date, endDate: Date, hotelId?: string): Promise<IRevenueResponseItemDto[]> {
        let axiosConfig = {}
        if (hotelId) {
            axiosConfig = {
                headers: {
                    'hotelId': hotelId,
                }
            }
        }
        
        const response = await AppDependencies.Axios.get(`/Revenue/${startDate.toISOString()}/${endDate.toISOString()}`, axiosConfig);
        if (response?.status === 200) {
            return response.data.items;
        }
        return [];
    }
}