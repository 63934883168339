import {ICustomerDto} from "../dtos/customers/ICustomerDto";
import {AppDependencies} from "./appDependencies";

export class CustomerService {
    async GetByUserName(): Promise<ICustomerDto | undefined> {
        const response = await AppDependencies.Axios.get("/Customers");
        if (response && response.status === 200) {
            return response.data;
        }
        return undefined;
    }
}