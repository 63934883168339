import {ICustomerDto} from "../../dtos/customers/ICustomerDto";
import React, {useEffect, useState} from "react";
import avatar from "../../assets/avatar7.png";
import {AppDependencies} from "../../services/appDependencies";
import {Breadcrumb, BreadcrumbItem} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {ChangePasswordModal} from "./ChangePasswordModal";
import MessengerChat from "../../components/FacebookChat";

const HomePage = () => {
    const {t} = useTranslation();
    const [customer, setCustomer] = useState<ICustomerDto | undefined>(undefined);
    const [userRoles, setUserRoles] = useState<string[]>([]);
    const [showChangePasswordModal, setShowChangePasswordModal] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            setCustomer(await AppDependencies.CustomerService.GetByUserName());
        }
        fetchData();
    }, [])

    const handleOpenChangePasswordModal = () => {
        setShowChangePasswordModal(true);
    }

    return (
        <>
            <Breadcrumb>
                <BreadcrumbItem>{t("Home")}</BreadcrumbItem>
                <BreadcrumbItem active>{t("MyProfile")}</BreadcrumbItem>
            </Breadcrumb>
            <div className="container">
                <div className="main-body">
                    <div className="row gutters-sm">
                        <div className="col-md-4 mb-3">
                            <div className="card shadow-lg">
                                <div className="card-body">
                                    <div className="d-flex flex-column align-items-center text-center">
                                        <img src={avatar} alt="Admin"
                                             className="rounded-circle" width="150"/>
                                        <div className="mt-3 text-wrap">
                                            <h4>{customer?.userName}</h4>
                                            <p className="text-secondary mb-1">{userRoles}</p>
                                        </div>
                                        <div className="mt-3"><a href="#" onClick={handleOpenChangePasswordModal}>Change
                                            my password...</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="card mb-3  shadow-lg">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">{t("LegacyName")}</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {customer?.legacyName}
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">{t("Email")}</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {customer?.email}
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">{t("Phone")}</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {customer?.phoneNumber}
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">{t("TIN")}</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {customer?.tin}
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">{t("Address")}</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {customer?.billingAddress}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <ChangePasswordModal show={showChangePasswordModal} onClose={() => setShowChangePasswordModal(false)}/>
        </>
    )
}

export default HomePage;