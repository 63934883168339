import logo from "../../assets/logo.svg";
import {LanguageSelector} from "../../components/LanguageSelector";
import {Resolver, SubmitHandler, useForm} from "react-hook-form";
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {selectLoginStatus, signInUser} from "../../redux/slices/identitySlice";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ILoginRequestDto} from "../../dtos/accounts/ILoginRequestDto";
import {useState} from "react";
import {toast} from "react-hot-toast";
import {AppDependencies} from "../../services/appDependencies";


type FormValues = {
    email: string;
}

const resolver: Resolver<FormValues> = async (values) => {
    const errors: Partial<Record<keyof FormValues, { type: string; message: string }>> = {};

    if (!values.email) {
        errors.email = {
            type: 'required',
            message: 'Old password is required.',
        };
    }

    return {
        values: Object.keys(errors).length === 0 ? values : {},
        errors,
    };
};

export const ForgotPasswordPage = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {register, handleSubmit, formState: {errors}} = useForm<FormValues>({resolver});
    const {t} = useTranslation();
    const navigate = useNavigate();

    const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
        try {
            setIsSubmitting(true);
            const identityService = AppDependencies.IdentityService;
            const result = await identityService.ForgotPassword(data.email);
            if (result) {
                navigate("/forgot-password-success");
                toast.success("Email sent successfully");
                return;
            }
        } catch (e) {
            console.log(e);
        } finally {
            setIsSubmitting(false);
        }
        toast.error("Sending email failed. Please try again");
        // const requestDto: ILoginRequestDto = {...data};
        // const result = await dispatch(signInUser(requestDto));
        // if (result.type === "account/signIn/fulfilled") {
        //     navigate("/home");
        //     return;
        // }
        // // toast.error("Invalid login attempt. Please try again!");
        // reset();
    }

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                        <div className="card border-0 shadow-lg rounded-3 my-5 mx-5">
                            <div className="card-body p-4 p-sm-5">
                                <div className="text-center mb-5">
                                    <img src={logo} alt="logo"/>
                                </div>
                                <form className={"needs-validation"} onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-floating mb-3 has-validation">
                                        <input className={`form-control ${errors?.email ? "invalid" : ""}`}
                                               type="text" placeholder={`${t("Email")}...`}
                                               {...register("email", {required: true})}/>
                                        <label htmlFor="floatingInput">{t("Email")}</label>
                                        {errors?.email && <div className="invalid-feedback">{errors?.email?.message}</div>}
                                    </div>
                                    <div className="d-grid pt-3">
                                        <button className="btn btn-secondary btn-login text-uppercase fw-bold"
                                                type="submit" disabled={isSubmitting}>
                                            {isSubmitting &&
                                                <span className="spinner-border spinner-border-sm me-2"></span>}
                                            {t("Send")}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}