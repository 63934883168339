import {RootState} from "../store";
import {AppDependencies} from "../../services/appDependencies";
import {createAsyncThunk, createSlice, isAnyOf} from "@reduxjs/toolkit";
import {createBrowserHistory} from "history";
import {IUser} from "../../dtos/accounts/IUser";

const history = createBrowserHistory();

interface IdentityState {
    token?: string;
    tokenExpire?: string;
    user?: IUser;
    // userPermissions: IUserPermission[];
    loginStatus: "none" | "pending" | "fulfilled" | "rejected";
}

const initialState: IdentityState = {
    // userPermissions: [],
    loginStatus: "none"
}

export const signInUser = createAsyncThunk(
    "identity/signIn",
    async (data: any, thunkAPI) => {
        try {
            const tokenData = await AppDependencies.IdentityService.login(data.userName, data.password);
            if (!tokenData) {
                return thunkAPI.rejectWithValue({error: "unauthorized"});
            }
            localStorage.setItem("userData", JSON.stringify(tokenData));
            return tokenData;
        } catch (error) {
            return thunkAPI.rejectWithValue({error: "unauthorized"});
        }
    }
);

export const fetchCurrentUser = createAsyncThunk(
    "identity/fetchUser",
    async (data: any, thunkAPI) => {
        try {
            const user = await AppDependencies.IdentityService.currentUser();
            localStorage.setItem("applicationUser", JSON.stringify(user));
            return user;
        } catch (error) {
            //@ts-ignore
            return thunkAPI.rejectWithValue({error: error.data});
        }
    }
);

export const identitySlice = createSlice({
    name: "identity",
    initialState,
    reducers: {
        doLogout: state => {
            state.token = undefined;
            state.user = undefined;
            // state.userPermissions = [];
            localStorage.removeItem("applicationUser");
            history.push("/home");
        }
    },
    extraReducers: (builder => {
        builder.addMatcher(isAnyOf(signInUser.fulfilled, fetchCurrentUser.fulfilled),
            (state, action) => {
                const tokenData = action.payload;
                if (tokenData) {
                    const {token, tokenExpire} = tokenData;
                    state.token = token;
                    const claims = JSON.parse(atob(token.split('.')[1]));
                    let roles = claims["Roles"];
                    if (typeof roles === "string") {
                        roles = [roles];
                    }
                    state.user = {
                        userName: claims["UserName"],
                        employeeId: claims["EmployeeId"],
                        lockoutEnabled: false,
                        roles
                    }
                    // state.userPermissions = AppDependencies.PermissionService.getPermissionsByRole(roles);
                    AppDependencies.Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
                }
                state.loginStatus = "fulfilled";
            });

        builder.addMatcher(isAnyOf(signInUser.pending, fetchCurrentUser.pending), (state, action) => {
            state.loginStatus = "pending";
        });

        builder.addMatcher(isAnyOf(signInUser.rejected, fetchCurrentUser.rejected), (state, action) => {
            state.loginStatus = "rejected";
        });
    })
});

export const {doLogout} = identitySlice.actions;

export const selectAuthToken = (state: RootState) => state.identity.token;
export const selectAuthUser = (state: RootState) => state.identity.user;
// export const selectUserPermissions = (state: RootState) => state.account.userPermissions;
export const selectLoginStatus = (state: RootState) => state.identity.loginStatus;

export const identity = identitySlice.reducer;
