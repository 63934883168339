import "./IdentityLayout.css";
interface IProps {
    children: any;
}

export const IdentityLayout = ({children}:IProps) => {
  return (
      <div className={"body"}>
          {children}
      </div>
  )
}