import {IHotelDto} from "../dtos/hotels/IHotelDto";
import {AppDependencies} from "./appDependencies";

export class HotelService {
    async GetHotels(): Promise<IHotelDto[] | undefined> {
        const response = await AppDependencies.Axios.get("/Hotels");
        if (response.status === 200) {
            return response.data;
        }
        return undefined;
    }
}