import {useAppSelector} from "../hooks/reduxHooks";
import {selectAuthToken} from "../redux/slices/identitySlice";
import {Navigate, useLocation} from "react-router-dom";
import React from "react";

interface IRequireAuthProps {
    children: any;
}

const RequireAuth = ({children}: IRequireAuthProps) => {
    const token = useAppSelector(selectAuthToken);
    const location = useLocation();

    if (!token) {
        return <Navigate to={"/login"} state={{from: location}} />
    }

    return (
        <>
            {children}
        </>
    )
}

export default RequireAuth;
