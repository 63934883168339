import logo from "../../assets/logo.svg";
import {SubmitHandler, useForm} from "react-hook-form";
import {ILoginRequestDto} from "../../dtos/accounts/ILoginRequestDto";
import {selectLoginStatus, signInUser} from "../../redux/slices/identitySlice";
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {NavLink, useNavigate} from "react-router-dom";
import {LanguageSelector} from "../../components/LanguageSelector";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import {toast} from "react-hot-toast";

interface ILoginForm {
    userName: string;
    password: string;
}

const LoginPage = () => {
    const {register, handleSubmit, formState: {errors, isDirty}, reset} = useForm<ILoginForm>();
    const dispatch = useAppDispatch();
    const loginStatus = useAppSelector(selectLoginStatus);
    const navigate = useNavigate();

    const {t} = useTranslation();

    const onSubmit: SubmitHandler<ILoginForm> = async (data: ILoginForm) => {
        const requestDto: ILoginRequestDto = {...data};
        const result = await dispatch(signInUser(requestDto));
        if (result.type === "identity/signIn/fulfilled") {
            navigate("/home");
            return;
        }
        // toast.error("Invalid login attempt. Please try again!");
        reset();
    }

    useEffect(() => {
        if (loginStatus === "rejected") {
            toast.error("Invalid login attempt. Please try again!");
            reset();
        }
    }, [loginStatus]);

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                        <div className="card border-0 shadow-lg rounded-3 my-5 mx-5">
                            <div className="card-body p-4 p-sm-5">
                                <div className="text-center mb-5">
                                    <img src={logo} alt="logo"/>
                                </div>
                                <form className={"needs-validation"} onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-floating mb-3 has-validation">
                                        <input className={`form-control ${errors?.userName ? "invalid" : ""}`}
                                               type="text" placeholder={`${t("UserName")}...`}
                                               {...register("userName", {required: true})}/>
                                        <label htmlFor="floatingInput">{t("UserName")}</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input className={`form-control ${errors?.password ? "invalid" : ""}`}
                                               type="password" placeholder={`${t("Password")}...`}
                                               {...register("password", {required: true})}/>
                                        <label htmlFor="floatingPassword">{t("Password")}</label>
                                    </div>
                                    <div className="d-grid pt-3">
                                        <button className="btn btn-secondary btn-login text-uppercase fw-bold"
                                                type="submit" disabled={loginStatus === "pending"}>
                                            {loginStatus === "pending" &&
                                                <span className="spinner-border spinner-border-sm me-2"></span>}
                                            {t("SignIn")}
                                        </button>
                                    </div>
                                    <div className="d-grid pt-3">
                                        <LanguageSelector showTitle={true}/>
                                    </div>
                                    <div className="text-center mt-3"><NavLink to="/forgot-password">Forgot
                                        password?</NavLink></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginPage;