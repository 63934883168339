import {NavMenu} from "../components/NavMenu";
import "./MainLayout.css";
import {LanguageSelector} from "../components/LanguageSelector";
import {HotelSelector} from "../components/HotelSelector";
import 'devextreme/dist/css/dx.light.css';
import MessengerChat from "../components/FacebookChat";
import React from "react";

interface IProps {
    children: any;
}

export const MainLayout = ({children}: IProps) => {
    return (
        <>
            <div className={"page"}>
                <div className={"sidebar"}>
                    <NavMenu/>
                </div>
                <main>
                    <div className={"top-row px-4"}>
                        <div className={"d-flex"}>
                            <div className={"flex-grow-1"}></div>
                            <HotelSelector/>
                            <div className={"mx-2"}/>
                            <LanguageSelector showTitle={false}/>
                        </div>
                    </div>
                    <article className={"content mx-4"}>
                        {children}
                    </article>
                </main>
            </div>
            <MessengerChat
                pageId="108689314229473"
                appId="YOUR_FACEBOOK_APP_ID"
                themeColor="#0084ff"
                loggedInGreeting="Hi! How can we help you?"
                loggedOutGreeting="Hi! How can we help you?"
                greetingDialogDisplay="show"
                greetingDialogDelay={10}
            />
        </>
    )
}