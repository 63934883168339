import {AppDependencies} from "./appDependencies";
import {IRoomEventDto} from "../dtos/rooms/IRoomEventDto";
import {IDocumentAmountResponseDto} from "../dtos/rooms/IDocumentAmountResponseDto";
import {format} from "date-fns";
import {IRoomEventAppointment} from "../dtos/rooms/IRoomEventAppointment";

export class CalendarService {
    async GetEvents(): Promise<IRoomEventDto[]> {
        let axiosConfig = {}
        // if (hotelId) {
        //     axiosConfig = {
        //         headers: {
        //             'hotelId': hotelId,
        //         }
        //     }
        // }
        //
        const response = await AppDependencies.Axios.get('/RoomEvents', axiosConfig);
        if (response?.status === 200) {
            return response.data;
        }
        return [];
    }

    async GetAmounts(startDate: Date, endDate: Date): Promise<IRoomEventDto[]> {
        let axiosConfig = {}
        // if (hotelId) {
        //     axiosConfig = {
        //         headers: {
        //             'hotelId': hotelId,
        //         }
        //     }
        // }
        
        const response = await AppDependencies.Axios.get(`/RoomEvents/days/${startDate.toISOString()}/${endDate.toISOString()}`, axiosConfig);
        if (response?.status === 200) {
            return response.data;
        }
        return [];
    }

    async GetDocumentAmounts(documentId: string): Promise<IDocumentAmountResponseDto[]> {
        let axiosConfig = {}

        const response = await AppDependencies.Axios.get(`/RoomEvents/calendar/${documentId}`, axiosConfig);
        if (response?.status === 200) {
            return response.data;
        }
        return [];
    }
    
    async GetCalendarAppointments(startDate: Date, endDate: Date): Promise<IRoomEventAppointment[]> {
        let axiosConfig = {}
        // if (hotelId) {
        //     axiosConfig = {
        //         headers: {
        //             'hotelId': hotelId,
        //         }
        //     }
        // }

        const startCalendarDateStr = format(startDate, "yyyy-MM-dd");
        const endCalendarDateStr = format(endDate, "yyyy-MM-dd");
        
        const response = await AppDependencies.Axios.get(`/RoomEvents/calendar/${startCalendarDateStr}/${endCalendarDateStr}`, axiosConfig);
        if (response.status === 200) {
            return response.data;
        }
        return [];
    }
}