import React, {forwardRef, useRef, MutableRefObject, useEffect} from 'react';
import ReactToPrint from 'react-to-print';
import {useParams} from "react-router-dom";
import {AppDependencies} from "../../services/appDependencies";
import {IFolioInvoiceDto} from "../../dtos/folios/IFolioInvoiceDto";
import {format} from "date-fns";
import "./InvoicePrintForm.css";
import logo from "../../assets/logo.svg";

interface Props {
    folio: IFolioInvoiceDto | undefined;
}

const ComponentToPrint = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const {folio} = props;
    if (!folio) return <></>
    return (
        <div ref={ref} className={"overflow-auto"}>
            <div className={"print-invoice-page"}>
                <div className={"d-flex"}>
                    <img src={logo} className={"print-invoice-page-logo"} alt={"logo"}/>
                    <div className={"mx-5"}></div>
                    <div className={"flex-grow-1"}>
                        <div>
                            <div>{folio?.hotelName}</div>
                            <div>{folio?.hotelPhone}</div>
                            <div>{folio?.hotelAddress}</div>
                            <div>{folio?.hotelCompany}</div>
                            <div>TIN: {folio?.hotelTin}</div>
                        </div>
                    </div>
                </div>
                <div className={"row my-4"}>
                    <div className={"col-12 text-center"}>
                        <span
                            style={{fontWeight: "bold"}}>Folio #{folio?.documentNumber} of {format(folio?.documentDate, "dd/MM/yyyy")}</span>
                    </div>
                </div>
                <div className={"row my-4"}>
                    <table>
                        <tbody>
                        <tr>
                            <td>
                                <b>Room:</b> {folio?.roomName}
                            </td>
                            <td>
                                <b>Beneficiary Bank Account</b> {folio?.beneficiaryBankAccount}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Customer:</b> {folio?.customerName}
                            </td>
                            <td>
                                <b>Beneficiary Bank</b> {folio?.beneficiaryBank}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>TIN:</b> {folio?.customerTin}
                            </td>
                            <td>
                                <b>Beneficiary Bank BIC</b> {folio?.beneficiaryBankBIC}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Currency:</b> {folio?.currencyName}
                            </td>
                            <td>
                                <b>Beneficiary Bank IBAN</b> {folio?.beneficiaryBankIBAN}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className={"row"}>
                    <table className={"table table-bordered"}>
                        <thead>
                        <tr>
                            <th>Period</th>
                            <th>Service</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Amount</th>
                            <th>Payment</th>
                        </tr>
                        </thead>
                        <tbody>
                        {folio?.folioTransactions.map((folioTransaction) => {
                            return (
                                <tr key={folioTransaction.id}>
                                    <td>{format(folioTransaction.period, "dd/MM/yyyy")}</td>
                                    <td>
                                        {folioTransaction.serviceDescription}
                                    </td>
                                    <td className={"text-end"}>{folioTransaction.price}</td>
                                    <td className={"text-end"}>{folioTransaction.quantity}</td>
                                    <td className={"text-end"}>{(folioTransaction.debtAdvance > 0 ? folioTransaction.debtAdvance : 0).toFixed(2)}</td>
                                    <td className={"text-end"}>{(folioTransaction.debtAdvance > 0 ? 0 : -folioTransaction.debtAdvance).toFixed(2)}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                <div className={"text-end"}>
                    <b>Total amount charged</b> {folio.totalAmount.toFixed(2)} {folio.currencyName}
                </div>
                <div className={"text-end"}>
                    <b>Including VAT</b> {folio.totalVatIncludedInAmount.toFixed(2)} {folio.currencyName}
                </div>
                <div className={"text-end"}>
                    <b>Total amount paid</b> {folio.totalPaid.toFixed(2)} {folio.currencyName}
                </div>
                <div className={"text-end"}>
                    <b>Balance</b> {folio.balance.toFixed(2)} {folio.currencyName}
                </div>
                <div className={"row mt-3"}>
                    <div className={"col-12"}>
                        <table className={"table table-bordered w-100"}>
                            <thead>
                            <tr>
                                <th>VAT Rate</th>
                                <th>Amount without VAT</th>
                                <th>VAT</th>
                                <th>Amount with VAT</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className={"text-end"}>{folio.vatRate} %</td>
                                <td className={"text-end"}>{folio.amountWithoutVat.toFixed(2)}</td>
                                <td className={"text-end"}>{folio.vatAmount.toFixed(2)}</td>
                                <td className={"text-end"}>{folio.amountWithVat.toFixed(2)}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                {/*<div className={"mt-5"}>*/}
                {/*    Manager _________________________ {folio.responsiblePerson}*/}
                {/*</div>*/}
                {/*<div className={"mt-5"}>*/}
                {/*    Thank You for visiting our hotel!*/}
                {/*</div>*/}
            </div>
        </div>
    )
});

export const InvoicePrintForm: React.FC = () => {
    const componentRef = useRef<HTMLDivElement | null>(null);
    const {folioId} = useParams<{ folioId: string }>();
    const [folio, setFolio] = React.useState<IFolioInvoiceDto | undefined>(undefined);

    const fetchData = async (id: string | undefined) => {
        if (!id) {
            return;
        }
        const folioService = AppDependencies.FolioService;
        const folio = await folioService.getFolioById(id);
        setFolio(folio);
    }

    useEffect(() => {
        fetchData(folioId)
    }, [folioId]);

    return (
        <div>
            <ReactToPrint
                trigger={() => <button className={"btn btn-primary"}>Print</button>}
                content={() => componentRef.current}
            />
            <ComponentToPrint ref={componentRef} folio={folio}/>
        </div>
    );
};