import axios from "axios";
import {currentApi} from "../configs/apiConfig";
import {ITokenDto} from "../dtos/accounts/ITokenDto";
import {toast} from "react-hot-toast";
import {AppDependencies} from "./appDependencies";
import {IChangePasswordCabinetDto} from "../dtos/accounts/IChangePasswordCabinetDto";
import {IChangePasswordWebDto} from "../dtos/accounts/IChangePasswordWebDto";

export class IdentityService {
    async login(userName: string, password: string): Promise<ITokenDto | undefined> {
        const body = {
            userName,
            password
        };
        try {
            const response = await AppDependencies.Axios.post(`${currentApi}/identity/login`, body, {});
            if (response && response.status === 200) {
                return response.data;
            }
            return undefined;
        } catch (error) {
            console.log(error)
        }
        
        return undefined;
    }

    async currentUser() {
        const response = await axios.get(`${currentApi}/currentUser`);
        // const responseDto = response.data;
        // return responseDto.data;
    }

    async getUserPhoto(userName: string): Promise<string | undefined> {
        try {
            const url = `${currentApi}/identity/photo/${userName}`;
            const response = await AppDependencies.Axios.get(url);
            return response.data;
        } catch (e) {
            return undefined;
        }
    }
    
    async ForgotPassword(email: string): Promise<boolean> {
        try {
            const body = {
                email
            };
            const response = await AppDependencies.Axios.post(`${currentApi}/identity/forgot-password`, body, {});
            if (response.status === 200) {
                return true;
            }
        } catch (e) {
            console.log(e)
        }
        
        toast.error("Something went wrong while sending email. Please check the email!");
        
        return false;
    }

    async ChangePasswordCabinet(data: IChangePasswordCabinetDto): Promise<boolean> {
        try {
            const body = {...data};
            const response = await AppDependencies.Axios.post(`${currentApi}/identity/change-password/cabinet`, body, {});
            if (response.status === 200) {
                return true;
            }
            console.log(2);
        } catch (e) {
            console.log(e)
        }
        
        toast.error("Something went wrong while changing password. Please check the old password!");
        
        return false;
    }

    async ChangePasswordWeb(data: IChangePasswordWebDto): Promise<boolean> {
        try {
            const body = {...data};
            const response = await AppDependencies.Axios.post(`${currentApi}/identity/change-password/web`, body, {});
            if (response.status === 200) {
                return true;
            }
            console.log(2);
        } catch (e) {
            console.log(e)
        }
        
        return false;
    }
}
