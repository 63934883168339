// MessengerChat.tsx
import React, { useEffect } from 'react';

interface MessengerChatProps {
    pageId: string;
    appId: string;
    themeColor?: string;
    loggedInGreeting?: string;
    loggedOutGreeting?: string;
    greetingDialogDisplay?: 'show' | 'hide' | 'fade';
    greetingDialogDelay?: number;
}

const MessengerChat: React.FC<MessengerChatProps> = ({
                                                         pageId,
                                                         appId,
                                                         themeColor = '#0084ff',
                                                         loggedInGreeting = 'Hi! How can we help you?',
                                                         loggedOutGreeting = 'Hi! How can we help you?',
                                                         greetingDialogDisplay = 'show',
                                                         greetingDialogDelay = 10,
                                                     }) => {
    useEffect(() => {
        //@ts-ignore
        window.fbAsyncInit = function () {
            //@ts-ignore
            window.FB.init({
                appId: appId,
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v16.0',
            });
        };
        (function (d, s, id) {
            var js: any,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
            //@ts-ignore
            fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
    }, [appId]);

    return (
        <div>
            <div id={"fb-customer-chat"}
                className="fb-customerchat"
                data-attribution="biz_inbox"
                data-page_id={pageId}
                data-theme_color={themeColor}
                data-logged_in_greeting={loggedInGreeting}
                data-logged_out_greeting={loggedOutGreeting}
                data-greeting_dialog_display={greetingDialogDisplay}
                data-greeting_dialog_delay={greetingDialogDelay}
            ></div>
        </div>
    );
};

export default MessengerChat;
